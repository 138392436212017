import { Link } from "@remix-run/react";
import logo from "../images/logo.svg";

export function Footer(): JSX.Element {
  return (
    <footer className="flex-1 border-t-[8px] border-solid border-secondary-500 bg-primary-900 py-10 md:pb-20">
      <div className="ml-auto mr-auto max-w-4xl pl-5 pr-5 flex gap-10 flex-col">
        <img
          alt=""
          className="relative h-6 w-[91.6px] shrink-0 overflow-hidden"
          loading="eager"
          src={logo}
        />
        <div className="flex flex-col gap-10 md:flex-row">
          <div className="flex flex-col gap-5 shrink-0">
            <div className="text-primary-200 text-sm font-normal">
              Institucional
            </div>
            <Link
              className="text-secondary-200 text-sm font-semibold"
              to="/sobre"
              reloadDocument
            >
              Sobre o HelpSaúde
            </Link>
          </div>
          <div className="text-primary-100 text-sm font-normal">
            <span>O uso deste site implica na aceitação total do </span>
            <Link className="font-semibold" to="/termos-de-uso" reloadDocument>
              Termo de Uso
            </Link>
            <span> e </span>
            <Link className="font-semibold" to="/privacidade" reloadDocument>
              Privacidade
            </Link>
            <span>
              . O material desse site constitui apenas uma fonte de informação,
              jamais deve ser encarado como substituto a uma consulta,
              tratamento ou diagnóstico providos por um prestador de serviços
              médicos. © Copyright 2023 - HelpSaúde.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
