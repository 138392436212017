import { Button } from "@/app/components/ui/button";
import { getErrorMessage } from "@/app/lib/misc.tsx";
import {
  type ErrorResponse,
  Link,
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { Search } from "lucide-react";
import GhostSolid from "../images/ghost-solid.svg";
import { Footer } from "./footer";
import { Header } from "./header";
import { Wrapper } from "./wrapper";

type StatusHandler = (info: {
  error: ErrorResponse;
  params: Record<string, string | undefined>;
}) => JSX.Element | null;

export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => (
    <p>
      {error.status} {error.data}
    </p>
  ),
  statusHandlers,
  unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: {
  defaultStatusHandler?: StatusHandler;
  statusHandlers?: Record<number, StatusHandler>;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  const params = useParams();

  if (typeof document !== "undefined") {
    console.error(error);
  }

  return (
    <>
      <Header />
      <Wrapper className="pt-6 pb-12 md:pt-20 md:pb-52 flex flex-col gap-6">
        {/* <div className="flex flex-col items-center gap-2">
          <div className="bg-warning-0 rounded-[32px] h-fit w-fit flex px-4 py-4 items-center">
            <img src={GhostSolid} alt="Página não encontrada" className="" />
          </div>
          <div className="text-neutral-500 text-base font-semibold">
            Erro 404
          </div>
          <div className="text-primary-900 text-2xl font-semibold">
            Página não encontrada
          </div>
        </div> */}
        <div>
          {isRouteErrorResponse(error)
            ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
                error,
                params,
              })
            : unexpectedErrorHandler(error)}
        </div>
        {/* <div className="flex flex-col gap-6 md:flex-row md:justify-stretch">
          <div className="flex-1 text-sm font-normal text-neutral-900 px-6 py-6 flex flex-col gap-4 bg-neutral-50 rounded-3xl">
            <p className="text-neutral-900 text-center text-sm font-normal">
              Lamentamos, mas a página que você está procurando não foi
              encontrada.
            </p>
            <p className="text-neutral-900 text-center text-sm font-semibold">
              Mas não se preocupe, estamos aqui para ajudá-lo a encontrar o que
              precisa:
            </p>
            <div className="flex flex-col justify-center md:items-center">
              <Link to="/" reloadDocument>
                <Button className="md:w-40" size="fill">
                  <Search className="mr-2 h-5 w-5" /> Nova Busca
                </Button>
              </Link>
            </div>
            <p className="text-neutral-900 text-center text-sm font-normal">
              No Helpsaude, nos esforçamos para fornecer informações de
              qualidade sobre profissionais de saúde de todo o Brasil.
            </p>
            <p className="text-neutral-900 text-center text-sm font-normal">
              Se você acredita que este erro é algo que precisamos corrigir, por
              favor, entre em contato conosco. Sua contribuição é essencial para
              melhorarmos continuamente.
            </p>
          </div>
        </div> */}
      </Wrapper>
      <Footer />
    </>
  );
}
