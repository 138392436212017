import { Link } from "@remix-run/react";
import type { User } from "@supabase/supabase-js";
import { CircleUserRound, LogOut, Search, Settings } from "lucide-react";
import logo from "../images/logo.svg";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

interface HeaderProps {
  searchButton?: boolean;
  user?: User | undefined | null;
}

export function Header({ searchButton, user }: HeaderProps): JSX.Element {
  const center = false;

  return (
    <header className="bg-primary-600">
      <div
        className={`ml-auto mr-auto flex h-16 max-w-4xl items-center px-5 pl-5 pr-5 md:h-24 ${
          center ? "md:justify-center" : "justify-start"
        }`}
      >
        <Link to="/" aria-current="page" reloadDocument>
          <img
            alt=""
            className="relative h-6 w-[90px] shrink-0 overflow-hidden md:h-8 md:w-[121px]"
            loading="eager"
            src={logo}
          />
        </Link>
        {user ? (
          <div className="flex flex-row items-center gap-2 ml-auto">
            <UserDropdown />
          </div>
        ) : null}
        {searchButton ? (
          <button
            className="flex cursor-pointer flex-row items-start justify-start bg-[transparent] p-0 [border:none]"
            type="button"
          >
            <div className="flex flex-row items-center justify-center gap-[8px] rounded-lg bg-primary-100 px-4 py-2.5">
              <Search className="h-4 w-4" />
            </div>
          </button>
        ) : null}
      </div>
    </header>
  );
}

function UserDropdown() {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <CircleUserRound className="h-8 w-8 cursor-pointer" color="white" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuItem className="flex items-center">
          <Link to="/admin" className="flex items-center w-full">
            <Settings className="mr-2 h-4 w-4" />
            <span>Admin</span>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="flex items-center text-red-600">
          <Link to="/logout" className="flex items-center w-full">
            <LogOut className="mr-2 h-4 w-4" />
            <span>Sair</span>
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
